var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.bookedEvents.length)?_c('b-container',{staticClass:"booked-events-container",attrs:{"fluid":""}},[_c('b-row',{attrs:{"no-gutters":""}},_vm._l((_vm.bookedEvents),function(event,index){return _c('b-col',{key:index,attrs:{"cols":"6"}},[_c('div',{staticClass:"booked-event"},[_c('div',[_c('router-link',{attrs:{"to":_vm.getRoute(event.url)}},[_c('h1',{staticClass:"event-name"},[_vm._v("\n              "+_vm._s(event.name)+"\n            ")])])],1),_c('div',[(event.banner)?_c('b-img',{staticClass:"event-banner img-fluid",attrs:{"src":event.banner}}):_vm._e(),(event.main_banner)?_c('b-img',{staticClass:"event-banner img-fluid",attrs:{"src":event.main_banner}}):_vm._e()],1),_c('div',[(_vm.eventMonths(event.start_date, event.end_date))?_c('p',{staticClass:"event-date"},[_vm._v("\n            "+_vm._s(_vm.$formatDate.getUTCDate(event.start_date))+" -\n            "+_vm._s(_vm.$formatDate.getUTCDate(event.end_date))+"\n            "+_vm._s(_vm.$formatDate.getUTCMonth(event.start_date))+"\n          ")]):_c('p',{staticClass:"event-date"},[_vm._v("\n            "+_vm._s(_vm.$formatDate.getUTCDate(event.start_date))+"\n            "+_vm._s(_vm.$formatDate.getUTCMonth(event.start_date))+" -\n            "+_vm._s(_vm.$formatDate.getUTCDate(event.end_date))+"\n            "+_vm._s(_vm.$formatDate.getUTCMonth(event.end_date))+"\n          ")])]),_c('div',[(event.awards_venue)?_c('p',{staticClass:"event-venue"},[_vm._v("\n            "+_vm._s(event.city)+" • "+_vm._s(event.awards_venue)+"\n          ")]):_vm._e(),(event.hotel_details)?_c('p',{staticClass:"event-venue"},[_vm._v("\n            "+_vm._s(event.city)+" • "+_vm._s(event.hotel_details.name)+"\n          ")]):_vm._e()]),_c('div',{class:(_vm.isMpActive(
              event.meeting_planner_is_active,
              event.meeting_planner_activation_date
            ) &&
              event.display_participants &&
              _vm.isAvailable(event.participant_customers)) ||
            _vm.isAward(event.url)
              ? 'bottom-btn'
              : 'inactiveMP'},[(
              _vm.isEvent(event.url) &&
              event.meeting_planner_is_active &&
              _vm.isMpActive(
                event.meeting_planner_is_active,
                event.meeting_planner_activation_date
              ) &&
              event.display_participants &&
              _vm.isAvailable(event.participant_customers)
            )?_c('router-link',{attrs:{"to":{ name: 'meetingPlanner', params: { eventId: event.id, slug: event.slug } }}},[_c('i',{staticClass:"fas fa-calendar-alt"}),_c('span',[_vm._v(" MEETING PLANNER")])]):(_vm.isEvent(event.url))?_c('router-link',{attrs:{"to":{ name: 'ccAllEvents', params: { eventId: event.id } }}},[_c('i',{staticClass:"fas fa-calendar-alt"}),_c('span',[_vm._v(" MEETING PLANNER")])]):_vm._e(),(_vm.isAward(event.url))?_c('router-link',{attrs:{"to":{
              name: 'globalAwardsEvents',
              params: { awardId: event.id, slug: event.slug },
            }}},[_vm._v("AWARD PAGE")]):_vm._e()],1)])])}),1)],1):_c('b-container',[_c('p',[_vm._v("No Booked Events")])])}
var staticRenderFns = []

export { render, staticRenderFns }