<template>
  <b-container fluid v-if="bookedEvents.length" class="booked-events-container">
    <b-row no-gutters>
      <b-col v-for="(event, index) in bookedEvents" :key="index" cols="6"
        ><div class="booked-event">
          <div>
            <router-link :to="getRoute(event.url)"
              ><h1 class="event-name">
                {{ event.name }}
              </h1></router-link
            >
          </div>
          <div>
            <b-img
              v-if="event.banner"
              :src="event.banner"
              class="event-banner img-fluid"
            />
            <b-img
              v-if="event.main_banner"
              :src="event.main_banner"
              class="event-banner img-fluid"
            />
          </div>
          <div>
            <p
              class="event-date"
              v-if="eventMonths(event.start_date, event.end_date)"
            >
              {{ $formatDate.getUTCDate(event.start_date) }} -
              {{ $formatDate.getUTCDate(event.end_date) }}
              {{ $formatDate.getUTCMonth(event.start_date) }}
            </p>
            <p class="event-date" v-else>
              {{ $formatDate.getUTCDate(event.start_date) }}
              {{ $formatDate.getUTCMonth(event.start_date) }} -
              {{ $formatDate.getUTCDate(event.end_date) }}
              {{ $formatDate.getUTCMonth(event.end_date) }}
            </p>
          </div>
          <div>
            <p class="event-venue" v-if="event.awards_venue">
              {{ event.city }} • {{ event.awards_venue }}
            </p>
            <p class="event-venue" v-if="event.hotel_details">
              {{ event.city }} • {{ event.hotel_details.name }}
            </p>
          </div>
          <div
            :class="
              (isMpActive(
                event.meeting_planner_is_active,
                event.meeting_planner_activation_date
              ) &&
                event.display_participants &&
                isAvailable(event.participant_customers)) ||
              isAward(event.url)
                ? 'bottom-btn'
                : 'inactiveMP'
            "
          >
            <router-link
              v-if="
                isEvent(event.url) &&
                event.meeting_planner_is_active &&
                isMpActive(
                  event.meeting_planner_is_active,
                  event.meeting_planner_activation_date
                ) &&
                event.display_participants &&
                isAvailable(event.participant_customers)
              "
              :to="{ name: 'meetingPlanner', params: { eventId: event.id, slug: event.slug } }"
            >
              <i class="fas fa-calendar-alt" /><span> MEETING PLANNER</span>
            </router-link>
            <router-link
              v-else-if="isEvent(event.url)"
              :to="{ name: 'ccAllEvents', params: { eventId: event.id } }"
            >
              <i class="fas fa-calendar-alt" />
              <span> MEETING PLANNER</span></router-link
            >
            <router-link
              v-if="isAward(event.url)"
              :to="{
                name: 'globalAwardsEvents',
                params: { awardId: event.id, slug: event.slug },
              }"
              >AWARD PAGE</router-link
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
  <b-container v-else>
    <p>No Booked Events</p>
  </b-container>
</template>

<script>
import redirectToMeetingPlanner from '../../utils/mixins/redirectToMeetingPlanner';
import BookedEvents from '../../utils/mixins/BookedEvents';

export default {
  name: 'MobileBookedEvents',
  mixins: [redirectToMeetingPlanner, BookedEvents],
};
</script>

<style scoped>
.booked-events-container {
  overflow: scroll;
}
.bottom-btn {
  background-color: #bd9d5f;
}
.bottom-btn a,
.inactiveMP a {
  color: white;
  font-size: 14px;
}
.inactiveMP {
  background-color: #d3d0c8;
}
.booked-event div {
  text-align: center;
  border-bottom: 1px solid #ebebeb;
  border-radius: 0 0 10px 10px;
  padding: 3px;
}
.booked-event {
  border-radius: 0 10px 10px 10px;
  background-color: white;
  margin-bottom: 10px;
  margin-right: 5px;
}
.fa-calendar-alt {
  font-size: 15px;
  color: white;
}

.event-banner {
  height: 10vh;
  width: 100%;
}
.event-name {
  text-transform: uppercase;
  color: #333333;
  text-align: center;
  font-size: 14px;
  margin: 2px 0;
}
.event-venue {
  font-family: Arial;
  font-size: 11px;
  line-height: 1.23;
  text-align: center;
  color: #707070;
  margin-bottom: 0px;
}
.event-date {
  font-family: Arial;
  font-weight: bold;
  font-size: 11px;
  line-height: 1.23;
  text-align: center;
  color: #707070;
  margin-bottom: 0px;
}
@media (max-width: 400px) {
  .event-name,
  .fa-calendar-alt,
  .bottom-btn a,
  .inactiveMP a {
    font-size: 10px;
  }
  .event-date,
  .event-venue {
    font-size: 9px;
  }
}
</style>
